  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-9-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Bouches-du-Rhône</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expert technique fissures dans les Bouches-du Rhône</h1>

<p>Les fissures sont un ouvrage peuvent avoir différentes causes et origines et être également plus conséquentes que d’autres.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Bouches-du-Rhône" />
</div>

<p>La forme des fissures, leur ouverture, leur épaisseur sont autant d’éléments qui informent l’Expert fissure sur les pathologies d’ouvrage ayant favorisé leur apparition. </p>

<p>Un Expert technique fissures aura pour mission d’évaluer les fissures, d’en déterminer les causes et de définir les travaux de reprise les plus adaptés, afin de recouvrer un ouvrage structurellement sain et pérenne.</p>

<p>L’expert fissure accompagne également les assurés en recours amiable, contre-expertise ou en judiciaire lorsque ceux-ci sont en litige avec un constructeur ou une assurance, pour défaut injustifié de garantie. </p>

<h2>L’Expertise technique fissure et expertise d’assuré </h2>

<p>L’Expert technique fissure intervient dans le cadre d’une expertise unilatérale, c'est-à-dire pour les besoins du client concernant ses problématiques de fissures sur ouvrage.</p>

<ul><li>D’où proviennent ces fissures ?</li>
<li>Ces fissures sont-elles “graves” ? </li>
<li>Engagent-elles la solidité structurelle de l’ouvrage ?</li>
<li>Quels sont les travaux de réparation les plus adaptés ?</li></ul>


<h2>Villes d’intervention dans les Bouches-du-Rhône</h2>
<p>Aix-en-Provence, Marseille, Martigues, Aubagne, Arles, Istres, La Ciotat, Gardanne, Ensuès-la-Redonne, Salon-de-Provence, Rognes, Venelles, Lambesc, Carry le Rouet, Gignac-la-Nerthe, Venelles, Meyrargues, Jouques, Saint Paul les Durances, Vitrolles, Saint Cannat, Bouc-bel-Air, Luynes</p>


<p></p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert fissure dans les Bouches-du-Rhône</a>
    </div>
</div>
<p> </p>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-bouche-rhone/' className='active'>Expert fissure Bouches-du-Rhône</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details